* {
  margin: 0;
  padding: 0;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #5dafb8;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card {
  height: 50vh;
  border-radius: 13px;
  box-shadow: 20px 40px 33px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  width: 35vw;
  background-color: #6cc2ce;
}

.product-canvas {
  width: 35vw;
  height: 38vh;
  background: white;
  box-shadow: 10px 15px 15px 6px #3891b4;
  border-radius: 0px;
  margin-bottom: 20px;
}

.colors {
  display: flex;
  flex-direction: row;
}

.colors div {
  padding: 8px;
}

.unset {
  all: unset;
}

#photo-editor-modal {
  height: 32rem !important;
  pointer-events: auto;
}

.reactEasyCrop_Container {
  right: unset;
  bottom: unset;
  position: relative !important;
  top: 20px !important;
  width: 100% !important;
  min-height: 400px !important;
}

.button-36 {
  width: 10rem;
  height: 5rem;
  background: #330066;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
}

@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}

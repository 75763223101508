.App {
  text-align: center;
}
.widthAndHeight {
  height: 50vh;
  width: 50vw;
  margin: 0;
  padding: 0;
  border: 10px;
  border-radius: 10px;
  border-width: 10px;
  border-style: ridge;
}

.border1 {
  border: 10px;
  border-radius: 10px;
  border-width: 10px;
  border-style: ridge;
}
.border-square {
  border: 10px;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #cfd1d6;
}

.v-height {
  min-height: 100vh;
}

.border2 {
  border: 10px;
  min-height: 40vh;
  max-width: 75vw;
  border-radius: 10px;
  border-width: 10px;
  border-style: ridge;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
  REMOVE ARROWS/SPINNERS------------------------------------------------------------------------
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
  PAGES STRUCTURE (HEADER / CONTENT / FOOTER)--------------------------------------------------- 
*/
#header {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
}
#content {
  padding-top: 35px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#footer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
  margin-top: auto;
}

/*
  CSS USED BY MULTIPLE PAGES AND COMPONENTS-------------------------------------------------------
*/

.section-col-2 {
  columns: 2 auto;
}

.addNoteLabel {
  cursor: pointer;
  font-size: small;
}

.addNoteLabel:hover {
  color: blue;
}

.inspectionDetailsTitle:hover {
  color: #cc92f8;
  cursor: pointer;
}

.option-menu {
  font-size: 16px;
  font-weight: 600;
}

.hover-blue:hover {
  color: rgb(9, 168, 212);
}

.colour-blue {
  color: rgb(9, 168, 212);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-blue-pointer:hover {
  color: rgb(9, 168, 212);
  cursor: pointer;
}

.cesiumContainer {
  max-width: 1200px;
  max-height: 1200px;
  margin: 0;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
}

.bg-alert {
  background-color: #f5f5f7;
}

.bg-gray {
  background-color: #a0a5af;
}

.bg-light-blue {
  background-color: rgb(87, 185, 218);
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.bg-light-gray {
  background-color: #f5f5f7;
}
.bg-lightest-gray {
  background-color: #fafafa;
}

.bg-dark-blue {
  background-color: #18294d;
}

.bg-blue {
  background-color: #074de3;
}

.bg-white {
  background-color: white;
}

.bg-title {
  /*background-color: #39393a;*/
  background-color: #18294d;
  color: rgb(252, 252, 252);
  font-weight: 700;
  font-size: 15px;
}

.bg-color-form {
  background-color: #f5f5f7;
}

.bg-color-light-blue {
  color: rgb(87, 185, 218);
}

.bg-color-table-blue {
  background-color: rgb(9, 168, 212);
}

.bg-colour-gray {
  background-color: rgb(233, 233, 233);
}

.bg-colour-gray-test {
  background-color: rgb(249, 246, 246);
}

.bg-colour-dark-gray {
  background-color: rgb(177, 176, 176) !important;
}

.bg-colour-blue {
  background-color: rgb(115, 200, 242);
}
.bg-colour-blue1:hover {
  background-color: rgb(37, 166, 230);
}

.bg-colour-orange {
  background-color: #fdab3e !important;
}
.bg-colour-orange:hover {
  background-color: #e68709 !important;
}
.bg-colour-green {
  background-color: #00c874;
}
.bg-colour-red {
  background-color: #e2435b;
}

.border-bottom-4 {
  border-bottom-color: #282c34;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.bidLeaderRecommendationAreaEditing {
  min-height: 85px;
  height: 85px;
  max-height: 150px;
}

.max-height-pdf {
  max-height: 1500px;
}

.max-width-pdf {
  max-width: 1350px;
}

.bidLeaderRecommendationAreaDisabled {
  min-height: 30px;
  height: 30px;
  max-height: 150px;
}

.border {
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0;
  margin: 5px;
}

.border-5px {
  border-radius: 5px;
  border-style: solid;
  border-color: #b8bdbb;
  padding-bottom: 8px;
  border-width: 1px;
}

.border-10px-white {
  border-radius: 10px;
  border-style: solid;
  border-color: white;
  border-width: 1px;
}

.border-none {
  padding: 0;
  border: none;
  background: none;
}

.border-gray {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top: #f5f5f7;
}

.border-gray-bottom {
  border-bottom: 2px solid #d2d2d3;
}

.border-radius {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.border-sides {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top: #f5f5f7;
  border-bottom: #f5f5f7;
}

.border-left {
  border-left: 2px solid white;
  height: 100%;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.decoration-none {
  text-decoration: none;
  cursor: auto;
}

div {
  padding: 0px;
  margin: 0px;
}

.div-overflow {
  overflow: auto;
}

html,
body {
  height: 100%;
  min-height: 100%;
  height: 100%;
}

.label-title {
  font-size: 12px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.noteArea {
  min-height: 70px;
  height: 70px;
  max-height: 150px;
}

.noteDelete {
  color: red;
}

.font-12 {
  font-size: 12px;
}

.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}

.field-size-20px {
  min-width: 20px;
  max-width: 20px;
}

.field-size-40px {
  min-width: 40px;
  max-width: 40px;
}

.field-size-100px-strict {
  min-width: 100px;
}

.field-size-50px {
  min-width: 50px;
  max-width: 50px;
}

.field-size-80px {
  min-width: 80px;
}

.field-size-100px {
  min-width: 100px;
}

.field-size-150px {
  min-width: 150px;
}

.field-size-200px {
  min-width: 200px;
}

.field-size-300px {
  min-width: 300px;
}

.field-size-400px {
  min-width: 400px;
}

.hover:hover {
  background-color: #f5f5f7;
}

.blue-bg {
  background-color: #f5f5f7;
}

.icons-social-media {
  color: aliceblue;
}

.img-pt {
  padding-top: 0px;
}

.img-status-size {
  width: 256px;
  height: 49px;
}

input[type="file"] {
  display: none;
}

.link-none {
  text-decoration: none;
  cursor: auto;
}
.link-decoration-none {
  text-decoration: none;
  cursor: auto;
  color: rgb(58, 58, 58);
}

.list-style-none {
  list-style-type: none;
}

.width-30px {
  min-width: 30px;
  max-width: 30px;
}
.width-50px {
  min-width: 50px;
  max-width: 50px;
}

.width-60px {
  min-width: 60px;
  max-width: 60px;
}
.width-90px {
  min-width: 90px;
  max-width: 90px;
}
.width-110px {
  min-width: 110px;
  max-width: 110px;
}
.width-120px {
  min-width: 120px;
  max-width: 120px;
}
.width-150px {
  min-width: 150px;
  max-width: 150px;
}
.width-240px {
  min-width: 240px;
  max-width: 240px;
}

.width-300px {
  min-width: 300px;
  max-width: 300px;
}

.width-250px {
  min-width: 350px;
}

.width-350px {
  min-width: 350px;
  max-width: 350px;
}
.width-500px {
  min-width: 500px;
  max-width: 500px;
}

.min-width-55px {
  min-width: 55px;
}
.min-width-90px {
  min-width: 90px;
}
.min-width-100px {
  min-width: 100px;
}

.min-width-130px {
  min-width: 130px;
}

.min-width-800px {
  min-width: 800px;
}
.min-width-1000px {
  min-width: 1000px;
}
.mt-30px {
  margin-top: 30px;
}

.mt-35px {
  margin-top: 38px;
}
.min-height-200px {
  min-height: 200px;
}

.min-width-1200 {
  min-width: 1400px;
}
.min-width-300 {
  min-width: 300px;
}

.min-height-800 {
  min-height: 800px;
}

.max-width-800 {
  max-width: 800px;
}

.max-width-100 {
  max-width: 100px;
}

.max-width-150 {
  max-width: 150px;
}

.max-height-200 {
  max-height: 200px;
}

.max-height-300 {
  max-height: 300px;
}

.max-height-600 {
  max-height: 600px;
}

.imagesParentDiv {
  display: flex;
  flex-wrap: wrap;
}

.imagesChildDiv {
  width: 250px;
  box-sizing: border-box;
}

.photoSectionMaxHeight {
  max-height: 300px;
  overflow: auto;
}

.max-height-navbar {
  max-height: 20px;
}

.border-none {
  border: none;
}

.option-icon {
  color: rgb(61, 61, 61);
  font-weight: 600;
}

p {
  padding: 0;
  margin: 0;
}

.panelRateButton {
  margin-left: 5%;
  margin-top: 5%;
}

.radius-5px {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.radius-10px {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.radius-top-5px {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.radius-top-10px {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.radius-bottom {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 20px;
}

.radius-bottom-5px {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.radius-top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.span-text {
  font-size: 12px;
  font-style: italic;
  color: brown;
}

.text-filter {
  font-size: 10px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}
.text-10 {
  font-size: 10px;
}
.text-11 {
  font-size: 11px;
}

.text-14 {
  font-size: 14px !important;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}

.text-30 {
  font-size: 30px;
}
.text-40 {
  font-size: 40px;
}
.text-50 {
  font-size: 50px;
}
.text-60 {
  font-size: 60px;
}

.text-color-red {
  color: #e2435b;
  color: #00c874;
}

.modelErrorHeight {
  height: 790px;
  display: flex;
  align-items: center;
}

.marginAuto {
  margin: auto;
}
.text-green {
  color: #0ed145;
}

.text-label {
  font-size: 12px;
  color: rgb(75, 74, 74);
  font-weight: 500;
}
.text-label-red {
  font-size: 12px;
  color: rgb(240, 39, 39);
  font-weight: 500;
}
.text-center {
  text-align: center;
}

.text-sub-titles {
  font-size: 16px;
  color: rgb(37, 36, 36);
  font-weight: 600;
}

.text-gray {
  color: rgb(75, 74, 74);
}

.text-ligth-gray {
  color: rgb(219, 218, 218);
}

.text-form-title {
  font-size: 24px;
  color: rgb(75, 74, 74);
  font-weight: 500;
}

.commentSectionTextArea {
  max-height: 150px;
  min-height: 100px;
}

.text-comment {
  font-size: 12px;
  color: black;
  font-weight: 400;
}

.text-comment-author {
  font-size: 14px;
  color: rgb(19, 2, 170);
  font-weight: 700;
}

.text-decoration-none {
  text-decoration: none;
}

.text-filter-title {
  font-size: 12px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-filter-title:hover {
  color: rgb(24, 136, 211);
}

.text-filter-title-open {
  font-size: 12px;
  color: rgb(24, 136, 211);
  font-weight: 600;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-modals {
  font-size: 16px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-pt {
  padding-top: 15px;
  font-size: 14px;
}

.text-orange {
  color: orange;
}

.text-pt-white {
  padding-top: 15px;
  font-size: 14px;
  color: white;
}

.text-pt-orange {
  padding-top: 15px;
  font-size: 14px;
  color: orange;
}

.text-pt-purple {
  padding-top: 15px;
  font-size: 18px;
  color: purple;
}

.text-colour-blue {
  color: rgb(87, 185, 218);
}

.vertical-center {
  display: flex;
  align-items: center;
}

.colour-red {
  color: red;
}

.colour-light-red {
  color: rgb(211, 128, 128);
}

.colour-light-red:hover {
  color: rgb(216, 40, 40);
  cursor: pointer;
}

.colour-light-purple {
  color: rgb(177, 156, 217);
}

.colour-light-purple:hover {
  color: purple;
  cursor: pointer;
}

.colour-light-blue {
  color: rgb(126, 180, 230);
}

.colour-light-blue:hover {
  color: rgb(44, 141, 231);
  cursor: pointer;
}

.text-title {
  font-size: 14px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-12 {
  font-size: 12px;
}

.text-white {
  color: rgb(247, 247, 247);
}

.link {
  color: white;
}

.link:hover {
  color: blue;
  cursor: pointer;
}
.page-item.active .page-link {
  z-index: 0;
  color: #fff;
  background-color: silver !important;
  border-color: silver !important;
}
.page-link {
  color: #000 !important;
}

.text-red {
  color: rgb(212, 22, 22);
}

.text-title:hover {
  color: rgb(50, 87, 206);
}

.text-right {
  text-align: right;
}

.text-navbar {
  font-size: 16px;
  color: #2f3c4d;
}

.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}

.width-80 {
  margin-left: 10px;
  margin-right: 10px;
}

.width-80P {
  margin-left: 10%;
  margin-right: 10%;
}



.width-20 {
  min-width: 20px;
  max-width: 20px;
  background-color: #18294d;
}

.width-40 {
  min-width: 40px;
  max-width: 40px;
}

.width-60 {
  min-width: 60px;
  max-width: 60px;
}

.width-70 {
  min-width: 70px;
  max-width: 70px;
}

.width-90 {
  min-width: 90px;
  max-width: 90px;
}

.width-100 {
  min-width: 100px;
  max-width: 100px;
}
.width-120 {
  min-width: 120px;
  max-width: 120px;
}
.width-140 {
  min-width: 140px;
  max-width: 140px;
}
.width-160 {
  min-width: 160px;
  max-width: 160px;
}
.width-170 {
  max-width: 1170px;
  min-width: 170px;
  background-color: #00c874;
}
.width-180 {
  min-width: 180px;
  max-width: 180px;
}
.width-200 {
  min-width: 200px;
  max-width: 200px;
}
.width-240 {
  min-width: 240px;
  max-width: 240px;
}

.size-add-picture {
  min-width: 202px;
  max-width: 202px;
  min-height: 202px;
  max-height: 202px;
}

.width-max-500 {
  max-width: 500px;
}
.width-600 {
  min-width: 600px;
  max-width: 600px;
}
.width-1000 {
  min-width: 1000px;
  max-width: 1000px;
}

.image-width {
  min-width: 1000px;
  max-width: 1000px;
}

.min-width-panelRoles {
  min-width: 400px;
}

.buttonContainer {
  position: absolute;
  z-index: 3;
}

.outline-light-blue {
  outline: rgb(87, 185, 218);
}

/* 
  --------- DROP SHADOW STYLING 
*/

.main-body {
  padding: 15px;
}

.card-user {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f5f5f7;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.card-body-user {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.box {
  margin-right: 10px;
  text-align: center;
}

.box--end {
  margin-left: auto;
}

.z-100 {
  z-index: 100;
  pointer-events: auto;
}
.carousel-inner {
  pointer-events: none;
  z-index: 10;
  width: 100%; /*this must stay */
  height: 100%; /*this must stay */
}

/*  
  CONDITIONAL RENDERING BY PAGE WIDTH
*/

@media (min-width: 1300px) {
  .width-80 {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media (max-width: 1000px) {
  .img-pt {
    padding-top: 8px;
  }

  .text-pt {
    padding-top: 5px;
    font-size: 10px;
  }
  .text-pt-white {
    padding-top: 5px;
    font-size: 10px;
    color: white;
  }
  .text-pt-orange {
    padding-top: 5px;
    font-size: 10px;
    color: orange;
  }
  .img-status-size {
    width: 260px;
    height: 50px;
  }
}

@media (max-width: 600px) {
  #hide-600px {
    display: none;
  }
}

@media (max-width: 1500px) {
  .image-width {
    min-width: 1000px;
    max-width: 1000px;
  }
}
@media (max-width: 1050px) {
  .image-width {
    min-width: 700px;
    max-width: 700px;
  }
}

@media (max-width: 700px) {
  .image-width {
    min-width: 450px;
    max-width: 450px;
  }
}
